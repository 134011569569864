<template>


  <div id="overlay" v-if="overlay"></div>
  <Dialog
      :style="{width: '33vw'}"
      v-model:visible="computedVisible"
      :modal="true"
      :closable="true">
<!--    <pre>{{user}}</pre>-->
    <div id="overlay" v-if="overlay"></div>
    <template #header>
      <h3>{{ action }} usuario: {{ user && user.name }}</h3>
    </template>

    <div class="form">
      <!--      <pre>{{ vv.$errors }}</pre>-->
      <form @submit.prevent="handleSubmit" class="p-fluid" autocomplete="off">
        <div class="grid">

          <div class="col-12 lg:col-6 xl:col-6">
            <input type="hidden" name="id" class="form-control" :value="user.id">
            <!--        Name -->
            <EditLabel ref="name" :input="user.name" title="Nombre" :required="true"/>
            <!--        Email -->
            <EditLabel ref="email" :input="user.email" title="Correo Electrónico" :required="true" :email="true"/>
            <!--        Send verification email-->
            <div class="verify">

              <EditDropdown
                  ref="bVerify"
                  :options="getBooleanStatus()"
                  optionLabel="label"
                  optionValue="code"
                  :selectedInput="user.verified?true:false"
                  :required="true"
                  title="Verificado"
              />
            </div>

          </div>

          <div class="col-12 lg:col-6 xl:col-6">
            <!--          Estatus-->
            <EditDropdown
                ref="status_id"
                :options="getUserStatus()"
                optionLabel="label"
                optionValue="code"
                :selectedInput="user.status_id?Number(user.status_id): 2"
                :required="true"
                title="Estatus"
            />
            <!--        Password-->
            <EditLabel ref="password" :input="user.password" title="Contraseña" />
          </div>
        </div>
        <!--        Submit button-->
        <!--        <Button type="button" @click="vv.$touch()">$touch</button>-->
        <div class="flex justify-content-end">
          <Button
              class="p-button-raised" icon="pi pi-save"
              type="submit"
              @click="vv.$touch()"
              :disabled="vv.$errors[0] || !vv.$anyDirty"
          />
        </div>
      </form>
    </div>
  </Dialog>
  <Toast/>
</template>

<script>
import {useVuelidate} from "@vuelidate/core";
import generalMixin from "../../../..//mixins/generalMixin";
import EditLabel from "@/components/misc/Labels/EditLabel";
import EditDropdown from "@/components/misc/Labels/EditDropdown";
import AgencyService from "../../../../service/backend/AgencyService";


export default {
  props: {
    show: Boolean,
    user: Object,
    agency: Object,
    action: String,
  },
  components: {
    EditDropdown,
    EditLabel,
  },
  mixins: [generalMixin],
  created() {
    this.$primevue.config.locale = {accept: 'Si', reject: 'No'};
    this.agencyService = new AgencyService();
    this.identity = {
      roleId: this.$store.getters.getProfile.role_id,
      entityId: this.$store.getters.getProfile.entity_id,
    };
    // this.selectedStatusId = (this.user && this.user.status_id) ? this.user.status_id : 2;
  },
  setup() {
    let vv = useVuelidate()
    return {vv};
  },
  data() {
    return {
      submitted: false,
      showMessage: false,
      showPassword: false,

      name: '',
      email: '',
      password: '',
      confirmPassword: '',
      selectedRoleId: '',
      selectedStatusId: 1,
      selectedEntityId: '',

      overlay: false,
      identity: null,
    }
  },

  methods: {
    handleSubmit() {
      this.submitted = true;
      const user = {
        id: this.user.id,
        name: this.$refs.name.content,
        email: this.$refs.email.content,
        password: this.$refs.password.content,
        role_id: this.selectedRoleId,
        status_id: this.$refs.status_id.content,
        agency_id: this.user.agency_id,
        verified: this.$refs.bVerify.content,
      };
      this.saveUser(user);
    },
    loadForm(user) {
      this.id = user.id;
      this.name = user.name;
      this.email = user.email;
      this.password = user.password;
      this.confirmPassword = user.password;

      this.selectedRoleId = 5;// see public.role table for user roles
      this.selectedStatusId = user.status_id ? user.status_id : 2;
      this.selectedEntityId = user.agency_id;

      this.showPassword = !this.id;
      this.submitted = false;
    },

    async saveUser(user) {
      try {
        user.agency_id = this.agency.id;
        user.role_id = 5;
        this.overlay = true;
        // Encrypt password here
        user.password = user.password ? this.encodePassword(user.password) : user.password;
        if (user.id) {
          await this.agencyService.updateUser(user);
          this.userDialog = false;
          this.$emit('close-reload');
          this.$toast.add({severity: 'success', summary: '', detail: `Usuario ${user.name} editado`, life: 3000});
        } else {
          const newUser = await this.agencyService.createUser(user);
          if (newUser && newUser.error) {
            this.$toast.add({
              severity: 'error',
              summary: '',
              detail: 'No se pudo crear/editar el usuario | ' + newUser.error,
              life: 10000
            });
          } else {
            this.$toast.add({severity: 'success', summary: '', detail: `Usuario ${user.name} creado`, life: 3000});
            this.$emit('close-reload', newUser);
          }
        }

      } catch (e) {
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'No se pudo crear/editar el usuario | ' + e.message,
          life: 10000
        });
      }
      this.overlay = false;
    },

  },
  computed: {
    computedVisible: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close')
      }
    },
  }
}
</script>

<style lang="scss" scoped>

form {
  margin-top: 2rem;
}

.field {
  margin-bottom: 3rem;
}

.changePassword {
  top: -6px;
}

.form {
  margin-top: -30px;
}

.verify {
  margin: 0 10px 0 5px;
}
</style>