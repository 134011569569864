<template>
  <div>
    <DataTable :value="installData"
               style="width: 100%;"
               class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">
      <Column field="id_psxcmxbanxdisxagc" header="ID de agencia" style="text-align: center;"/>
      <Column field="id_taquilla" header="Número de Taquilla" style="text-align: center;"/>
      <Column field="tusuario" header="Usuario" style="text-align: center;"/>
      <Column field="password" header="Contraseña" style="text-align: center;">
        <template #body>
          {{ this.password }}
        </template>
      </Column>

    </DataTable>

  </div>

  <div class="flex justify-content-end">
    <Button icon="pi pi-print" class="p-button-raised" :onClick="print"/>
  </div>
  <div id="printout" style="display: none;">

    <div>

      <div style="width: 50%; border: solid 1px black; padding: 20px;">
        <div id="printMe" style="text-align: center;">
          <h3>Datos de instalación</h3>
        </div>

        <div style="display: flex; justify-content: center;">
          <div style="border: solid 1px black; padding: 10px; width: 200px; font-weight: bold;">Nombre de Agencia:</div>
          <div style="border: solid 1px black; padding: 10px; width: 200px; ">{{ this.agency.tagencia }}</div>
        </div>
        <div style="display: flex; justify-content: center;">
          <div style="border: solid 1px black; padding: 10px; width: 200px; font-weight: bold;">ID de Agencia:</div>
          <div style="border: solid 1px black; padding: 10px; width: 200px;">{{ this.agency.id_psxcmxbanxdisxagc }}</div>
        </div>
        <br>


        <div v-for="(value, key) in installData" :key="key">
          <div style="display: flex; justify-content: center;">
            <div style="border: solid 1px black; padding: 10px; width: 200px; font-weight: bold;">Número de Taquilla:
            </div>
            <div style="border: solid 1px black; padding: 10px; width: 200px; font-weight: bold;">Usuario:</div>
            <div style="border: solid 1px black; padding: 10px; width: 200px; font-weight: bold;">Contraseña:</div>
          </div>
          <div style="display: flex; justify-content: center;">
            <div style="border: solid 1px black; padding: 10px; width: 200px; text-align: center;">{{
                value.id_taquilla
              }}
            </div>
            <div style="border: solid 1px black; padding: 10px; width: 200px; text-align: center;">{{
                value.tusuario
              }}
            </div>
            <div style="border: solid 1px black; padding: 10px; width: 200px; text-align: center;">123456</div>
          </div>
        </div>
        <br>
      </div>
    </div>

  </div>


</template>

<script>
import AgencyService from "@/service/backend/AgencyService";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "Tab3",
  mixins: [generalMixin],
  created() {
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
    this.agencyService = new AgencyService;
  },
  props: {
    agency: Object,
    agencyDetails: Object,
  },
  data() {
    return {
      params: null,
      installData: null,
      idAgencia: null,
      password: null,
      output: null,
    }
  },
  async mounted() {
    this.installData = await this.agencyService.getAgencyInstallData(this.agency);
    this.idAgencia = this.agency.id_agencia;
    this.password = '123456';
  },
  methods: {

    print() {
      let contentOfDiv = document.getElementById("printout").innerHTML;
      let newWin = window.open('', '', 'height=650, width=650');
      newWin.document.write(contentOfDiv);
      newWin.document.close();
      newWin.print();
    }
  }

}
</script>

<style scoped lang="scss">


.content {
  width: 100%;
}

.input-text {
  margin: 30px 0 30px 0;
}

.address {
  border: solid 1px lightgray;
  border-radius: 5px;
  margin-top: 5px;
  padding: 15px;
}

.address-label {
  //top: -0.75rem;
  margin-left: 12px;
  font-size: 12px;
  color: gray;
}

.container {
  display: flex;
  /*padding: 10px;*/
  border: solid 1px var(--surface-300);
  margin: 35px 0 15px 0;

  .title {
    margin-right: 10px;
    background-color: var(--primary-color2);
    color: white;
    padding: 10px 10px 10px 15px;
    min-width: 140px;
    font-weight: bold;
  }

  .content {
    padding: 10px 0 0 0;
    min-width: 70px;
    text-align: center;
    font-weight: bold;
  }
}

.table {
  display: block;
}

.row {
  display: block;
}

.cell {
  display: inline-block;
}

.p-button-raised{
  margin-top: 10px;
}

</style>