<template>
  <form @submit.prevent="confirmSaveData" class="p-fluid" autocomplete="off">
    <div class="flex justify-content-center">


      <Accordion style="width: 100%;" :multiple="true"  :activeIndex="0">
        <AccordionTab header="Lotería">

          <Dropdown v-model="selectedPaymentProfile"
                    :options="paymentProfiles"
                    optionLabel="tperfil_pago_premios"
                    optionValue="id_perfil_premiosxprocentajes"
                    style="width: 100%; margin-bottom: 10px;"
                    :onClick="getData"
                    placeholder="Seleccionar perfil de pago"/>

          <DataTable :value="percentages && percentages.loteria"
                     editMode="row" dataKey="id"
                     v-model:editingRows="editingRows"
                     @row-edit-save="onRowEditSaveLoteria"
                     responsiveLayout="scroll"
                     :loading="loadingTable"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">
                      <Column field="tperfil_pago_premios" header="Perfil de pago" style="text-align: left;"/>
            <Column field="tlista" header="Lista" style="text-align: left;"/>
            <Column field="nporcentaje_comision_agc" header="Porcentaje" style="text-align: center;">
              <template #body="{data}">
                {{ formatNumber(data.nporcentaje_comision_agc) }}%
              </template>
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" suffix="%" :min="0" :max="40" showButtons/>
              </template>
            </Column>
            <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
          </DataTable>
        </AccordionTab>
        <AccordionTab header="Animalitos">
          <DataTable :value="percentages && percentages.animalitos" editMode="row" dataKey="id"
                     v-model:editingRows="editingRows"
                     @row-edit-save="onRowEditSaveAnimalitos" responsiveLayout="scroll"
                     :loading="loadingTable"
                     class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table">
                      <Column field="tperfil_pago_premios" header="Perfil de pago" style="text-align: left;"/>
            <Column field="tlista" header="Lista" style="text-align: left;"/>
            <Column field="nporcentaje_comision_agc" header="Porcentaje" style="text-align: center;">
              <template #body="{data}">
                {{ formatNumber(data.nporcentaje_comision_agc) }}%
              </template>
              <template #editor="{ data, field }">
                <InputNumber v-model="data[field]" suffix="%" :min="0" :max="40" showButtons/>
              </template>
            </Column>
            <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
          </DataTable>
        </AccordionTab>
      </Accordion>
    </div>

    <div class="flex justify-content-end" style="margin-top: 10px;">
      <Button
          class="p-button-raised" icon="pi pi-save"
          type="submit"
          :disabled="!bDirty"
      />
    </div>
  </form>

  <Dialog v-model:visible="savePercentagesDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
    <div class="confirmation-content">
      <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
      <span>¿Está seguro que quiere editar los porcentajes seleccionados?</span>
    </div>
    <template #footer>
      <Button label="No" icon="pi pi-times" class="p-button-text" @click="savePercentagesDialog = false"/>
      <Button label="Si" icon="pi pi-check" class="p-button-text" @click="saveData"/>
    </template>
  </Dialog>
</template>

<script>
import AgencyService from "@/service/backend/AgencyService";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "Tab2",
  mixins: [generalMixin],
  created() {
    this.$primevue.config.locale.emptyMessage = "Buscando opciones...";
    this.agencyService = new AgencyService;
  },
  props: {
    agency: Object,
  },
  data() {
    return {
      percentages: null,
      paymentProfiles: null,
      editingRows: [],
      loadingTable: false,
      selectedPaymentProfile: null,
      bDirty: false,
      active: 0,
      savePercentagesDialog: false,
    }
  },
  async mounted() {
    this.paymentProfiles = await this.agencyService.getPaymentProfile({
      id_comercializador: this.agency.id_comercializador,
      id_distribuidor: this.agency.id_distribuidor,
      id_banca: this.agency.id_banca,
    });
    await this.getData();
  },
  methods: {
    async getData() {
      this.loadingTable = true;
      this.percentages = await this.agencyService.getAgencyPercentages({
        id_agencia: this.agency.id_agencia,
        id_perfil_pago_premios: this.selectedPaymentProfile
      });
      this.selectedPaymentProfile = this.paymentProfiles[0].id_perfil_premiosxprocentajes;
      this.loadingTable = false;
    },
    onRowEditSaveLoteria(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.percentages.loteria[index] = newData;
    },
    onRowEditSaveAnimalitos(event) {
      this.bDirty = true;
      let {newData, index} = event;
      this.percentages.animalitos[index] = newData;
    },
    confirmSaveData() {
      this.savePercentagesDialog = true;
    },
    async saveData() {
      let res = [];

      this.percentages.loteria.forEach((item) => {
        switch (item.id_lista) {
          case 0: // Terminales
            res.ter = Number(item.nporcentaje_comision_agc);
            break;
          case 1: // Triples
            res.tri = Number(item.nporcentaje_comision_agc);
            break;
          case 2: // Terminales con signo
            res.teresp = Number(item.nporcentaje_comision_agc);
            break;
          case 3: // Triples con signo
            res.triesp = Number(item.nporcentaje_comision_agc);
            break;
        }
      });

      const response = await this.agencyService.updateAgencyPercentages({
        // Loteria
        id_psxcmxbanxdisxagc: this.percentages.loteria[0].id_psxcmxbanxdisxagc,
        id_perfil_premiosxprocentajes: this.percentages.loteria[0].id_perfil_premiosxprocentajes,
        ter: res.ter,
        tri: res.tri,
        teresp: res.teresp,
        triesp: res.triesp,
        // Animalitos
        nporcentaje_comision_agc: Number(this.percentages.animalitos[0].nporcentaje_comision_agc),
        id_prestador_servicio: 100,
        id_comercializador: this.agency.id_comercializador,
        id_distribuidor: this.agency.id_distribuidor,
        id_banca: this.agency.id_banca,
        id_agencia: this.agency.id_agencia,
      });

      if (response.error) {
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: `No se pudo editar los porcentajes de la agencia ${this.agency.tagencia}| ` + response.error,
          life: 10000
        });
        this.overlay = false;
      } else {
        this.$toast.add({
          severity: 'success',
          summary: '',
          detail: `Porcentajes de agencia ${this.agency.tagencia} editados`,
          life: 3000
        });
        this.$emit('close', close);
      }

    }

  }
}
</script>

<style scoped lang="scss">


</style>