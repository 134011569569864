<template>
  <Dialog
      :style="{width: '60vw'}"
      v-model:visible="computedVisible"
      :modal="true"
      :closable="true">
    <div id="overlay" v-if="overlay"></div>

    <template #header>
      <h3>Usuarios de Agencia: {{ agency && agency.tagencia }} </h3>
    </template>

    <div>
      <div class="grid">

        <div class="col-12">

          <div class="card">
            <Toolbar class="mb-4">
              <template #start>
                <Button label="" icon="pi pi-plus" class="p-button-success mr-2"
                        @click="createUser"
                        :agency="agency"
                />
              </template>
              <template #end>
                <Button label="" icon="pi pi-trash" class="p-button-danger" @click="confirmDeleteSelected"
                        :disabled="!selectedAgencyUsers || !selectedAgencyUsers.length"/>
              </template>
            </Toolbar>

            <DataTable :value="agencyUsers"
                       v-model:selection="selectedAgencyUsers"
                       responsiveLayout="scroll"
                       class="p-datatable-gridlines p-datatable-striped p-datatable-sm editable-cells-table"
                       :paginator="true"
                       :rows="10"
                       :filters="filters"
                       :loading="loadingTable"
                       showGridlines
                       paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                       :rowsPerPageOptions="[5,10,25,50]"
                       currentPageReportTemplate="{first} al {last} de {totalRecords}"
                       :key="componentKey">

              <template #header>
                <div class="table-header flex flex-column md:flex-row md:justiify-content-between">
                  <h5 class="mb-2 md:m-0 p-as-md-center">Usuarios</h5>
                  <span class="p-input-icon-left">
                            <i class="pi pi-search"/>
                    <!--                            <InputText v-model="filters['global'].value" placeholder="Buscar..."/>-->
                        </span>
                </div>
              </template>
              <Column selectionMode="multiple" style="width: 3rem" :exportable="false"></Column>

              <Column field="status_id" header="Estatus" :sortable="true"
                      bodyStyle="text-align: center; white-space: nowrap !important;">
                <template #body="{data}">
                <span :class="'status-badge '+getUserStatusClass(data.status_id)" v-if="!data.locked">
                  {{ getUserStatus(data.status_id, data.locked) }}
                </span>
                  <span class="status-badge warning" v-if="data.locked">
                  BLOQUEADO
                </span>

                </template>
              </Column>
              <Column field="verified" header="Verificado" style="width:10px;text-align: center">
                <template #body="props" style="">
                  <Checkbox v-model="props.data.verified" :binary="true" :readonly="true" disabled/>
                </template>
                <div v-if="props.data.verified">Verificado</div>
              </Column>
              <Column field="name" header="Nombre" :sortable="true" style="min-width:12rem"></Column>
              <Column field="email" header="Correo Electrónico" :sortable="true" style="min-width:12rem"></Column>

              <Column field="createdAt" header="Fecha de creación" :sortable="true" style="min-width:4rem"
                      bodyStyle="text-align: center;">
                <template #body="{data}">
                  {{ formatDate(data.createdAt) }}
                </template>
              </Column>

              <Column field="lastLoginAt" header="Último acceso" :sortable="true" style="min-width:8rem">
                <template #body="{data}">
                  {{ formatDateAndtime(data.lastLoginAt) }}
                </template>
              </Column>
              <Column field="fromIP" header="Última IP" :sortable="true" style="min-width:8rem">
                <template #body="props">
                  {{ props.data.fromIP }}
                </template>
              </Column>

              <Column field="cstatus_agencia" header="Acción" style="width:10px">
                <template #body="props">
                  <div class="flex justify-content-between">
                  <span class="p-row-editor-init-icon pi pi-fw pi-lock edit-icon"
                        v-tooltip.bottom="'Bloquear'"
                        v-if="props.data.cstatus_agencia!==4"
                        v-bind:class="{'locked': props.data.locked, 'unlocked': !props.data.locked}"
                        @click="confirmBlockUser(props.data)"></span>
                    <span class="p-row-editor-init-icon pi pi-fw pi-pencil edit-icon"
                          v-tooltip.bottom="'Editar'"
                          @click="editUser(props.data)"/>
                  </div>

                </template>
              </Column>
              <Column field="verified" header="" style="text-align: center">
                <template #body="props" style="">
                  <Button label="Enviar correo de verificación" @click="sendVerifyEmail(props.data)"
                          v-if="!props.data.verified"/>
                </template>
              </Column>
            </DataTable>
          </div>

          <Dialog v-model:visible="blockUserDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
            <div class="confirmation-content">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
              <span v-if="user">¿{{
                  this.user.locked ? 'Desbloquear' : 'Bloquear'
                }} usuario <b>{{ user && user.name }}</b>?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="blockUserDialog = false"/>
              <Button label="Si" icon="pi pi-check" class="p-button-text" @click="blockUser(user)"/>
            </template>
          </Dialog>

          <Dialog v-model:visible="deleteUsersDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
            <div class="confirmation-content">
              <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
              <span v-if="user">¿Borrar los usuarios seleccionados?</span>
            </div>
            <template #footer>
              <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUsersDialog = false"/>
              <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteselectedAgencyUsers"/>
            </template>
          </Dialog>
        </div>
      </div>
    </div>

    <Dialog v-model:visible="blockUserDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
        <span v-if="user">¿{{
            this.user.locked ? 'Desbloquear' : 'Bloquear'
          }} usuario <b>{{ user && user.name }}</b>?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="blockUserDialog = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="blockUser(user)"/>
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteUsersDialog" :style="{width: '450px'}" header="Confirmar" :modal="true">
      <div class="confirmation-content">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
        <span v-if="user">¿Borrar los usuarios seleccionados?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-text" @click="deleteUsersDialog = false"/>
        <Button label="Si" icon="pi pi-check" class="p-button-text" @click="deleteselectedAgencyUsers"/>
      </template>
    </Dialog>

    <UserDialog
        :user="agencyUser"
        :agency="agency"
        :show="displayUserDialog"
        :action="header"
        ref="editUserDialog"
        @close-reload="closeUserDialogAndReload"
        @close="closeUserDialog"/>
    <Toast/>
  </Dialog>


  <Dialog header="Notificacion" v-model:visible="display">
    Verificacion enviada.
  </Dialog>
</template>

<script>
import generalMixin from "../../../../mixins/generalMixin";
// import EditLabel from "../../../misc/Labels/EditLabel.vue";
import AgencyService from "../../../../service/backend/AgencyService";
import UserDialog from "./UserDialog.vue";
import EmailService from "../../../../service/backend/EmailService";

export default {
  name: "EditAgencyUsersDialog",
  components: {UserDialog},
  props: {
    show: Boolean,
    agency: Object,
    agencyDetails: Object,
    agencyUsers: Object,
  },
  mixins: [generalMixin],
  data() {
    return {
      activeIndex: 0,
      overlay: false,
      blockUserDialog: false,
      deleteUsersDialog: false,
      displayUserDialog: false,
      user: {},
      agencyUser: {},
      selectedAgencyUsers: null,
      loadingTable: false,
      header: null,
      verifyLink: null,
      display: false,
    }
  },
  created() {
    this.agencyService = new AgencyService();
    this.emailService = new EmailService();
  },
  mounted() {
    // this.agencyUsers = this.users;
  },
  methods: {
    closeDialog() {
      this.$emit('close');
    },
    createUser() {
      this.agencyUser = {}
      this.header = 'Agregar';
      this.displayUserDialog = true;
    },
    editUser(user) {
      this.agencyUser = {...user};
      delete this.agencyUser['password'];
      // this.agencyUser.agencyId = this.agency.agencyId
      this.header = 'Editar';
      this.submitted = false;
      this.displayUserDialog = true;
    },
    confirmBlockUser(user) {
      this.blockUserDialog = true;
      this.user = user;
    },
    async blockUser(user) {
      this.blockUserDialog = false;
      this.loadingTable = true;
      const response = await this.agencyService.blockUser({id: user.id});
      if (response.error) {
        this.$toast.add({
          severity: 'success',
          summary: ``,
          detail: `El usuario ${user.name} no pudo ser ${!user.locked ? 'bloqueado' : 'desbloqueado'} | ---` + response.error,
          life: 3000
        });
      } else {
        this.$toast.add({
          severity: 'success',
          summary: ``,
          detail: `Usuario ${user.name} ${!user.locked ? 'bloqueado' : 'desbloqueado'}`,
          life: 3000
        });
      }
      this.$emit('close-reload')
      // await this.getData();

      this.loadingTable = false;
    },
    confirmDeleteSelected() {
      this.deleteUsersDialog = true;
    },
    deleteselectedAgencyUsers() {
      this.loadingTable = true;
      this.agencyService.deleteUser(this.selectedAgencyUsers);
      // this.agencyUsers = this.agencyUsers.filter(val => !this.selectedAgencyUsers.includes(val));
      this.$emit('close-reload');
      this.deleteUsersDialog = false;
      this.selectedAgencyUsers = null

      this.loadingTable = false;
      this.$toast.add({severity: 'success', summary: '', detail: 'Usuario(s) eliminado(s)', life: 3000});
    },
    validate(user) {
      return (!(user.name && user.email));
    },
    closeUserDialog() {
      this.displayUserDialog = false;
    },
    closeUserDialogAndReload() {
      this.$emit('close-reload')
      this.closeUserDialog();
    },
    sendVerifyEmail(user) {
      this.emailService.sendEmail({
        email: user.email,
        name: user.name,
        subject: "Verificar cuenta",
        message: this.getVerifyMessage(user)
      });
    },

    getVerifyMessage(user) {
      let verifyLink = "https://api.banklot.net/api/agency/user/verify?e=";
      let userData = JSON.stringify({
        email: user.email,
        id: user.id
      });
      verifyLink += btoa(this.encrypt(userData, this.getTodayDate()));

      verifyLink = encodeURI(verifyLink);
      this.verifyLink = verifyLink;
      this.display = true;

      return `<div style="background-color: lightgrey; padding: 30px;">
              <div style="background-color: white; padding: 30px; width: 400px;">
              <p><img src="http://grupobanklot.com/wp-content/uploads/2022/09/PNG-GRUPO-BANKLOT-EDITABLE-e1639164476644-1-2.png" /></p>
              <p><span style="color: #000000;"><span style="font-size: 20px;"><strong>Verificar cuenta</strong></span></span></p>
              <p><span style="color: #000000;">Hola ${user.name},</span></p>
              <p>&nbsp;</p>
              <p><span style="color: #000000;">Por favor verifique su direcci&oacute;n electr&oacute;nica para poder comenzar a usar la taquilla web.</span></p>
              <p>&nbsp;</p>
              <p style="text-align: center;"><strong><span style="color: #ffffff;">
              <a style="padding: 16px; background-color: #004a49; border: 1px solid #004A49; border-radius: 4px; width: 288px; line-height: 22px; text-decoration: none; text-align: center;
              color: #262637; display: inline-block; font-weight: 500;"
              href="${verifyLink}" target="_blank">
              <span style="color: #ffffff;"> Confirmar&nbsp;direcci&oacute;n electr&oacute;nica</span>
              </a></span></strong></p>
              <p>&nbsp;</p>
              <p><span style="color: #000000;">Si el bot&oacute;n no funciona, por favor copie la siguiente direcci&oacute;n en su navegador:</span></p>
              <p><a href="${verifyLink}">${verifyLink}</a></p>
              <p>&nbsp;</p>
              <div style="color: black">
                <p>¡Bienvenidos a bordo!</p>
                <p>@Grupo Banklot.</p>
              </div>
              <p>&nbsp;</p>
              </div>
              <div style="padding: 30px; margin-top: -20px; font-size: smaller; width: 500px">
              <p>Este correo ha sido enviado a <em>${user.email}</em> por Grupo Banklot en respuesta a la solicitud de creaci&oacute;n de una cuenta de usuario de agencia.</p>
              </div>
              </div>`;
    }
  },
  computed: {
    computedVisible: {
      get() {
        return this.show
      },
      set() {
        this.$emit('close')
      }
    },
  }
}
</script>

<style scoped>

</style>