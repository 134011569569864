<template>
  <div id="overlay" v-if="overlay"></div>
  <!--        <pre>{{ vv._vuelidate_11.content.$model }}</pre>-->
  <form @submit.prevent="handleSubmit" class="p-fluid" autocomplete="off">

    <div class="grid">
      <div class="col-12 lg:col-4 xl:col-4">
        <!--        tagencia-->
        <EditLabel ref="tagencia" :input="agency.tagencia" title="Nombre" :required="true"/>
        <!--        trif_agencia-->
        <EditLabel ref="trif_agencia" :input="agency.trif_agencia" title="RIF" :required="true"/>
        <!--        id_cod_reg_cona-->
        <EditLabel ref="id_cod_reg_cona" :input="agencyDetails.id_cod_reg_cona" title="Codigo Conalot" :required="true"/>
      </div>
      <div class="col-12 lg:col-4 xl:col-4">
        <!--        id_estado-->
        <EditDropDown
            ref="id_estado"
            :options="estados"
            optionLabel="testado"
            optionValue="id_estado"
            :selectedInput="agencyDetails.id_estado"
            @onChange="onChangeEstado"
            :loading="loadingEstados"
            title="Estado"
        />
        <!--        id_municipio-->
        <EditDropDown
            ref="id_municipio"
            :options="municipios"
            optionLabel="tmunicipio"
            optionValue="id_municipio"
            :selectedInput="selectedMunicipio"
            @onChange="onChangeMunicipio"
            :loading="loadingMunicipios"
            title="Municipio"
        />
        <!--        <div class="municipio-error"><small v-if="!selectedMunicipio" class="p-error">Campo obligatorio</small></div>-->
        <!--        cstatus_agencia-->
<!--        <EditLabel ref="cstatus_agencia" :input="agency.cstatus_agencia" title="% Comision" disabled/>-->
      </div>
      <div class="col-12 lg:col-4 xl:col-4">
        <!--        cstatus_agencia-->
        <EditDropDown
            ref="cstatus_agencia"
            :options="statusList"
            optionLabel="label"
            optionValue="code"
            :selectedInput="agencyDetails.cstatus_agencia"
            @onChange="onChangeStatus"
            title="Estatus"
        />
        <EditLabel ref="dcreacion" :input="formatDate(agencyDetails.dcreacion)" title="Fecha de creaciôn" disabled/>
      </div>
    </div>

    <!--  <Button type="button" @click="vv.$touch()">$touch</button>-->
    <div class="flex justify-content-end">
      <Button
          class="p-button-raised" icon="pi pi-save"
          type="submit"
          :disabled="vv.$errors[0] || !vv.$anyDirty"
      />
    </div>
  </form>

</template>

<script>

import useVuelidate from '@vuelidate/core'
import EditLabel from "@/components/misc/Labels/EditLabel";
import EditDropDown from "@/components/misc/Labels/EditDropdown";
import MainService from "@/service/backend/MainService";
import AgencyService from "@/service/backend/AgencyService";
import generalMixin from "@/mixins/generalMixin";

export default {
  name: "Tab1",
  components: {EditDropDown, EditLabel},
  mixins: [generalMixin],
  created() {
    this.mainService = new MainService;
    this.agencyService = new AgencyService;
  },
  setup() {
    let vv = useVuelidate()
    return {vv};
  },
  props: {
    agency: Object,
    agencyDetails: Object,
  },
  data() {
    return {
      estados: null,
      municipios: null,
      selectedMunicipio: null,
      overlay: false,
      statusList: null,
      loadingEstados: false,
      loadingMunicipios: false,
    }
  },
  async mounted() {
    this.loadingEstados = true;
    this.loadingMunicipios = true;
    this.selectedMunicipio = this.agencyDetails.id_municipio;
    this.estados = await this.mainService.getEstados();
    this.municipios = await this.mainService.getMunicipios({id_estado: this.agencyDetails.id_estado});
    this.statusList = this.getEntityStatus();
    this.loadingEstados = false;
    this.loadingMunicipios = false;
  },
  methods: {
    async onChangeEstado(id_estado) {
      this.selectedMunicipio = null;
      this.municipios = await this.mainService.getMunicipios({
        id_estado: id_estado.value,
      });
    },
    onChangeMunicipio(id_municipio) {
      this.selectedMunicipio = id_municipio.value;
    },
    onChangeStatus(cstatus_agencia) {
      this.selectedStatus = cstatus_agencia.value;
    },
    handleSubmit() {
      this.submitted = true;
      const agency = {
        id_agencia: this.agency.id,
        tagencia: this.$refs.tagencia.content,
        trif_agencia: this.$refs.trif_agencia.content,
        id_cod_reg_cona: this.$refs.id_cod_reg_cona.content,
        id_estado: this.$refs.id_estado.content,
        id_municipio: this.selectedMunicipio,//this.$refs.id_municipio.content,
        cstatus_agencia: this.selectedStatus,//this.$refs.cstatus_agencia.content,
      };
      this.saveAgency(agency);
    },
    async saveAgency(agency) {
      try {
        this.overlay = agency;
        const response = await this.agencyService.updateAgencyDetails(agency);
        if (response.error) {
          this.$toast.add({
            severity: 'error',
            summary: '',
            detail: 'No se pudo editar la agencia | ' + response.error,
            life: 10000
          });
          this.overlay = false;
        } else {
          this.$toast.add({severity: 'success', summary: '', detail: `Agencia ${agency.tagencia} editada`, life: 3000});
          this.$emit('close', close);
        }

      } catch (e) {
        console.log(e)
        this.$toast.add({
          severity: 'error',
          summary: '',
          detail: 'No se pudo editar la agencia | ' + e.message,
          life: 10000
        });
      }
      this.overlay = false;
    }
  }

}
</script>
<style>
.municipio-error {
  margin-top: -10px;
  margin-left: 10px;
}
</style>